import AbstractView from './abstract_view';
import Vue from 'vue/dist/vue.esm.js'
import axios from "axios";
import webApi from '../../web_api';
import Market from "../../constants/market"

export default class Client extends AbstractView {
    init_edit(self) {
        self.set_image();

        $('#client_edit_form').on('submit', function () {
            if (!self.is_change_agreement_status()) {
                return confirm('ステータスが変更されてます。クライアントにメールが送信されますがよろしいですか？');
            }
        });
    }

    init_new(self) {
        self.set_image();
    }

    // 契約ステータスを変更された場合のチェック
    is_change_agreement_status() {
        let defaultStatus = $('[data-default_status]').attr('data-default_status');
        return $('#client_agreement_status').val() <= defaultStatus
    }

    set_image() {

        // image削除ボタン
        $('[data-remove-button]').on('click', function (e) {
            let thisObj = $(e.currentTarget);
            let parentObj = thisObj.parent();
            parentObj.find('[data-remove]').val(1);
            parentObj.find('img').each(function (i, ele) {
                let d = $(ele).attr('data-default');
                $(ele).attr('src', d);
            });
            parentObj.find('input[type=file]').val('');
            thisObj.addClass('d-none');
        });

        // アップロードするファイルを選択
        $('input[type=file][data-image-file]').on('change', function (e) {
            let thisObj = $(e.currentTarget);
            let imageObj = thisObj.prev();
            let parentObj = thisObj.parent();
            parentObj.find('[data-remove]').val('');
            parentObj.find('[data-remove-button]').removeClass('d-none');

            let file = $(this).prop('files')[0];
            if (!file || !file.type.match('image.*')) {
                $(this).val('');
                return;
            }

            // アップロードする画像をバックグラウンドでサムネイル表示
            let reader = new FileReader();
            reader.onload = function () {
                imageObj.attr('src', reader.result);
            }
            reader.readAsDataURL(file);

            // 削除ボタン表示
            // parentobj.find('p').html('');
            // parentobj.find('[data-image-destroy-button]').removeClass('d-none');
        });
    }
}
window.loadClass('admin/clients', new Client());