// クラスロード処理
window.loadClass = (bodyname, classObject) => {
    document.addEventListener("turbolinks:load", () => {
        let controller_name = $('body').attr('data-controller');
        let method_name = $('body').attr('data-method');
        if (controller_name == (bodyname)) {

            // controller initialize call.
            classObject.begin();

            // method call.
            console.log(method_name);
            let function_pointer = eval('classObject.init_' + method_name)
            if (function_pointer != undefined) {
                function_pointer(classObject);
            }
        }
    });
}

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require('./admin/controller/analysis')
require('./admin/controller/client')
require('./admin/controller/client_product')
require('./admin/controller/my_page')

Rails.start()
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", () => {
    // 2重送信防止するためdisabled化
    $('input[type=submit],button[type=submit]').on('click', () => {
            setTimeout(() => {
                $(this).prop("disabled", true)
            }, 1);
        }
    );

    //### 2重チェック防止処理 ###
    $('form').on('submit', () => {
            return !$(this).find('input[type=submit]').hasClass('disabled')
        }
    );

    // form系は非同期で動かすのでエラーを独自で処理するために以下を追加
    $('form').on('ajax:send', (event) => {
            console.log("send")
            $(event.currentTarget).find('button').attr('disabled', 'disabled');
            $('.form_message').addClass('d-none');
            $('.form_message').html('');
            $('label.invalid').removeClass('invalid text-danger');
            let submitting_label = $(event.currentTarget).find('input[type=submit]').data('submitting-label');
            if (submitting_label != undefined) {
                $('body').prepend('<div class="submitting-modal" style="position: fixed; width: 100%; height: 100%; background: #00000094; z-index: 10000; top: 0; left: 0; display: flex; justify-content: center; align-items: center;"><div style="background: white; padding: 40px 60px; border-radius: 20px; fa-3x"><i class="fas fa-spinner fa-spin"></i> ' + submitting_label + '</div></div>')
            }
        }
    ).on('ajax:success', (event) => {
            console.log("success")
            $('.submitting-modal').remove();
            let data = event.originalEvent.detail[0];
            let status = event.originalEvent.detail[1];
            // console.log(data)

            if (status == 'OK' && data.full_message) {
                $(event.currentTarget).find('button').removeAttr('disabled');
                $('.form_message').removeClass('d-none');
                $('.form_message').html('<ul class="mb-0"><li>' + data.full_message.join('</li><li>') + '</li></ul>');
                for (let key in data.messages) {
                    $('[id="' + key + '_label"]').addClass('invalid text-danger');
                }
                $("html, body").animate({scrollTop: 0}, 500, "swing");
            }
        }
    ).on('ajax:error', (event) => {
        console.log("error")

        $('.submitting-modal').remove();
        $(event.currentTarget).find('button').removeAttr('disabled');
        let response_code = event.originalEvent.detail[2].status;
        // セッションタイムアウトの場合
        if (response_code == 401) {
            location.href = '';
            return;
        }

        $("html, body").animate({scrollTop: 0}, 500, "swing");
        $('.form_message').removeClass('d-none');

        // unicornのタイムアウトなどが発生した場合
        if (response_code == 502) {
            $('.form_message').html('<ul class="mb-0"><li>通信エラーが発生しました</li></ul>');
            return;
        }
        $('.form_message').html('<ul class="mb-0"><li>システムエラーが発生しました</li></ul>');
    });

    //### EnterでSubmitを防ぐ ###
    $(document).on("keypress", "select:not(.allow_submit),input:not(.allow_submit)", (event) => {
        return event.which != 13;
    });
});
require("shopify_app")
