import axios from "axios";
import Message from "packs/constants/message"

class MyWebApi {
    TIME_OUT = 3000;
    h_token: string = null;

    private async commonRequest(method, uri, data, timeout) {
        let config = {
            url: uri,
            timeout: timeout,
            method: method,
        }

        if (data && this.h_token != null) {
            data['hactkn'] = this.h_token
        }
        if (method == 'get') config['params'] = data
        else config['data'] = data

        return await axios.request(config).then((response) => {
            if (response.status == 200) {
                if (response.data.status_code == 1) {
                    return response.data;
                } else {
                    throw response.data.message;
                }
            } else {
                throw (`通信エラーが発生しました。http_status=${response.status}`)
            }
        })
    }

    private async commonGet(uri, data, timeout = this.TIME_OUT) {
        return await this.commonRequest('get', uri, data, timeout)
    }

    private async commonPost(uri, data, timeout = this.TIME_OUT) {
        return await this.commonRequest('post', uri, data, timeout)
    }

    private async commonPut(uri, data, timeout = this.TIME_OUT) {
        return await this.commonRequest('put', uri, data, timeout)
    }

    private async commonDelete(uri, data, timeout = this.TIME_OUT) {
        return await this.commonRequest('delete', uri, data, timeout)
    }

    // AgoraToken
    async fetch_host_rtc_token(channel, uid) {
        return await this.commonPost('/api/v1/token/fetch_rtc_token', {
            channelName: channel,
            uid: uid,
            role: 'host'
        })
    }

    // RealTimeMessagingToken
    async fetch_host_rtm_token(uid) {
        return await this.commonPost('/api/v1/token/fetch_rtm_token', {uid})
    }

    // AgoraToken
    async fetch_rtc_token(channel) {
        let data = {type: 'rtc', uid: 0, channelName: channel}
        return await axios.post('https://api.and-land.jp/fetchtoken', data)
    }

    // RealTimeMessagingToken
    async fetch_rtm_token(uid) {
        let data = {type: 'rtm', uid: uid}
        return await axios.post('https://api.and-land.jp/fetchtoken', data)
    }

    // カウント取得
    async fetch_counts(rid) {
        let data = {rid}
        return await axios.get('https://api.and-land.jp/gcount', {params: data})
    }

    // メッセージ履歴
    async get_histories(rid, s, e, type, ntoken = null) {
        let data = {rid, s, e, type}
        if (ntoken) {
            let encoded = encodeURIComponent(ntoken)
            data = {...data, ...{ntoken: encoded}}
        }
        return await axios.get('https://api.and-land.jp/gmsg', {params: data})
    }

    // メッセージを送信する
    async send_message(uid, rid, message, type, count = null) {
        let data = {uid, rid, message, type}
        if (count) data = {...data, ...{count}}
        return await this.commonPost('https://api.and-land.jp/pmsg', data)
    }

    // イベントを送信する
    async send_event(rid, type, count = 1) {
        let data = {rid, type, count}
        return await this.commonPost('https://api.and-land.jp/pcount', data)
    }


    // ストリーミング情報を取得
    async fetch_streaming(streaming_id) {
        let result = await this.commonGet(`/api/v1/streamings/${streaming_id}`, null)
        return result.data
    }

    // ストリーミング情報を作成する
    async create_streaming(streaming_id, modify) {
        let result = await this.commonPost(`/api/v1/streamings/${streaming_id}`, modify)
        return result.data
    }

    // ストリーミング情報を更新する
    async update_streaming(streaming_id, modify) {
        let result = await this.commonPut(`/api/v1/streamings/${streaming_id}`, modify)
        return result.data
    }

    // ショップにアクセスするキー情報を取得する
    async get_shop_code(uuid) {
        let result = await this.commonGet('/api/v1/shop_adapter/get_code', {uuid: uuid})
        return result.data;
    }


    async get_shop_adapter_items(uuid, page) {
        let result = await this.commonGet('/api/v1/shop_adapter/get_items', {uuid: uuid, page: page})
        return result.data;
    }

    // MakeShopから商品一覧を取得する
    async get_makeshop_items(uuid, page) {
        let result = await this.commonGet('/api/v1/make_shop/get_items', {uuid: uuid, page: page})
        return result.data;
    }

    // 商品追加（delete insertにする）
    async reset_products(streaming_id, products) {
        let result = await this.commonPost('/api/v1/products', {streaming_id: streaming_id, streaming_products: products})
        return result.data
    }

    // 商品情報を取得する
    async get_products(uuid) {
        let result = await this.commonGet('/api/v1/products', {uuid: uuid})
        return result.data.products
    }

    // 商品情報を削除する
    async delete_product(streaming_id, number) {
        let result = await this.commonDelete('/api/v1/products', {streaming_id: streaming_id, number: number})
        return result.data
    }

    // 商品情報を更新する
    async update_product(streaming_id, number, streaming_product) {
        let result = await this.commonPut('/api/v1/products', {streaming_id: streaming_id, number: number, streaming_product: streaming_product})
        return result.data
    }

    // メイン表示を切り替える
    async main_setting(streaming_id, number) {
        let result = await this.commonPut('/api/v1/products/main_setting', {streaming_id: streaming_id, number: number})
        return result.data
    }

    // 商品を非表示にする
    async display_setting(streaming_id, number, is_hide) {
        let result = await this.commonPut('/api/v1/products/display_setting', {streaming_id: streaming_id, number: number, is_hide: is_hide})
        return result.data
    }


    // RecordingIDを取得する
    async recording_acquire(id) {
        let result = await this.commonPost('/api/v1/recording/acquire', {id: id})
        return result.data
    }

    // Recordingを開始する
    async recording_start(id) {
        let result = await this.commonPost('/api/v1/recording/start', {id: id})
        return result.data
    }

    // Recording状況を取得する
    async recording_query(id) {
        let result = await this.commonPost('/api/v1/recording/query', {id: id})
        return result.data
    }

    // Recordingを開始する
    async recording_stop(id) {
        let result = await this.commonPost('/api/v1/recording/stop', {id: id})
        return result.data
    }

    // 累計ライブ再生時間を取得
    async get_live_minutes(streaming_uuid) {
        let result = await this.commonGet('/api/v1/admin/streaming/get_live_minutes', {uuid: streaming_uuid})
        return result.data.live_minutes
    }

    // 累計ライブ再生時間を取得
    async live_graph(streaming_uuid) {
        let result = await this.commonGet('/api/v1/admin/streaming/live_graph', {uuid: streaming_uuid})
        return result.data
    }

    // アーカイブグラフ情報を取得
    async archive_graph(streaming_uuid, from, to) {
        let result = await this.commonGet('/api/v1/admin/streaming/archive_graph', {uuid: streaming_uuid, from: from, to: to})
        return result.data
    }

    // ストリーミング情報の取得
    async get_counter(streaming_uuid) {
        let result = await this.commonGet(`/api/v1/streamings/${streaming_uuid}/get_counter`, {})
        return result.data
    }

    // クライアントのimage_icon_urlを取得
    async get_client_icon_url(streaming_uuid) {
        let result = await this.commonGet(`/api/v1/streamings/${streaming_uuid}/get_icon_image`, {})
        return result.data
    }
}

export default new MyWebApi;