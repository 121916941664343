import AbstractView from './abstract_view';
import Vue from 'vue/dist/vue.esm.js'
import axios from "axios";
import webApi from '../../web_api';
import Market from "../../constants/market"

export default class Analysis extends AbstractView {
    init_analysis(self) {
        const ctx = document.getElementById('liveChart').getContext('2d');
        const ctx2 = document.getElementById('archiveChart').getContext('2d');
        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    borderColor: '#FF0000',
                    fill: false,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4
                }
            ]
        };
        const config = {
            type: 'bar',
            options: {
                responsive: true,
                // layout: {
                //     padding: 50,
                // },
                plugins: {
                    legend: false,
                    title: {
                        display: true,
                        text: ''
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: '再生時間(分)'
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: false,
                            text: '件数'
                        },
                        beginAtZero: true,
                        suggestedMin: 0,
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                        },
                        min: 0,
                        // suggestedMax: 200
                    }
                }
            },
        };
        config.data = data;
        const charts = {
            'liveChart': {
                chart: new Chart(ctx, JSON.parse(JSON.stringify(config))),
                data: [],
                kinds: ['いいね数', 'コメント数', 'カート追加数', '総購入数', '同時視聴者数'],
                columns: ['hearts_diff', 'messages_diff', 'add_items_in_cart_diff', 'add_items_in_cart', 'live_users'],
                scales: {
                    x: {
                        title: {
                            text: '再生時間(分)',
                        },
                    }
                }
            },
            'archiveChart': {
                chart: new Chart(ctx2, JSON.parse(JSON.stringify(config))),
                data: [],
                kinds: ['日当たりの視聴者数', '日当たりの視聴時間（分）'],
                columns: ['viewer', 'counter'],
                scales: {
                    x: {
                        title: {
                            text: '対象日',
                        },
                    }
                }
            },
        }
        let chartChange = (chartTarget, kind) => {
            let chart = charts[chartTarget];
            let chartObj = chart.chart;
            chartObj.data.labels = [];
            chartObj.data.datasets[0].data = [];
            chartObj.options.plugins.title.text = chart.kinds[kind];
            let max = 0;
            for (let d of chart.data) {
                let amount = d[chart.columns[kind]];
                chartObj.data.datasets[0].data.push(amount);
                if (max < amount) max = amount;
                chartObj.data.labels.push(d.target_at)
            }
            chartObj.options.scales.y.max = max <= 1 ? max + 10 : max + parseInt(max / 5);
            chartObj.options.scales.x.title.text = chart.scales.x.title.text;
            chartObj.update();
        }

        webApi.live_graph($('#streaming_uuid').val()).then(res => {
            charts['liveChart'].data = res;
            chartChange('liveChart', 0);
            chartChange('archiveChart', 0);
        });

        $('.chart-hearder-item').on('click', function (ev) {
            let current = $(ev.currentTarget);
            const chartTarget = current.data('target');
            let parent = current.parent();
            let kind = current.data('kind');
            parent.find('.chart-hearder-item').removeClass('chart-header-active');
            parent.find(`[data-kind="${kind}"]`).addClass('chart-header-active');
            chartChange(chartTarget, kind);
        });

        //
        $(".datepicker").datepicker({
            dateFormat: "yy-mm-dd"
        });

        // アーカイブのグラフ表示
        $('#chartFind').on('click', function () {
            let from = $('#chartArchiveFrom').val();
            let to = $('#chartArchiveTo').val();

            webApi.archive_graph($('#streaming_uuid').val(), from, to).then(res => {
                if (res.graph.length == 0) {
                    alert('データが見つかりませんでした。')
                    return;
                }

                // グラフ更新
                charts.archiveChart.data = res.graph;
                chartChange('archiveChart', 0);

                // 表示データ更新
                $('#archiveGraphAreaData p[data-target]').each(function (i, el) {
                    let target = $(el).data('target');
                    $(el).html(res.data[target]);
                });

                $('#archiveGraphArea').removeClass('d-none'); // グラフ領域を表示
            });
        });
    }
}
window.loadClass('admin/streamings', new Analysis());